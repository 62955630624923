import { Pipe, PipeTransform } from "@angular/core";
import moment from 'moment';

@Pipe({
  name: "momentFormat",
  standalone: true,
  pure: true
})
export class MomentFormatPipeStandalone implements PipeTransform {
  transform(
    value: any,
    format: "time" | "default" | "complete" | null,
    customFormat?: string
  ): string {

    const formats = {
      time: "HH:mm",
      default: "MM-DD-YYYY",
      complete: "MM-DD-YYYY HH:mm" 
    };

    if (!value) return undefined;
    if (format) {      
      return moment(value).format(formats[format]);
    }
    if (customFormat) {
      return moment(value).format(customFormat);
    }
    return undefined;
  }
}
